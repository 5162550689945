import React, { useCallback, useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import { LogoutOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";
import Cookies from 'portal-cookie';
import { useDispatch } from 'react-redux';
import { actions } from "base/store";

const Logout = () => {

	const dispatch = useDispatch();
	const setAuth = useCallback((data) => {
		dispatch(actions.auth.setState(data))
	}, [dispatch]);

	useEffect(() => {
		Cookies.remove('authToken');
		setAuth({loggedIn: false, pending: false});
	}, [setAuth]);

	return (
		<Row className="ts-home-content-container">
			<Col xs={24}>
				<div className="ts-portal-card">
					<Typography.Title level={4}><LogoutOutlined /> Log Out</Typography.Title>
					<Typography>
						You have been successfully logged out.  Please click <Link to="/">here</Link> if you would
						like to log in again.
					</Typography>
				</div>
			</Col>
		</Row>

	)
};


export default Logout;
