import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunkMiddleware from "redux-thunk";
import layout, { actions as layoutActions } from './Layout/Layout.redux';
import auth, { actions as authActions } from './auth.redux';
import addresses, { actions as addressesActions } from 'pages/Addresses/Addresses.redux';
import invoices, { actions as invoiceActions } from 'pages/Invoices/Invoices.redux';
import orders, { actions as orderActions } from 'pages/Orders/Orders.redux';


export const history = createBrowserHistory({
	basename: process.env.REACT_APP_BASE_URL,
});


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	layout,
	auth,
	addresses,
	invoices,
	orders,
});



export const actions = {
	layout: layoutActions,
	auth: authActions,
	addresses: addressesActions,
	invoices: invoiceActions,
	orders: orderActions,
};



const store = createStore(
	createRootReducer(history),
	composeEnhancers(
		applyMiddleware(
			routerMiddleware(history),
			thunkMiddleware,
		),
	),
);


export default store;
