import axios from 'axios';
import Cookies from 'portal-cookie';
import getResponseErrors from "lib/getResponseErrors";



const initialState = {
	loggedIn: false,
	pending: false,
	showPasswordChange: false,
	showRelog: false,
	errors: [],
	resetToken: null,
	verifyingToken: false,
	showSignupModal: false,
};


export const types = {
	SET_STATE: 'AUTH/SET_STATE',
};




export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
}



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	handleLogin: (data) => async (dispatch, getState) => {
		console.log('[auth.js] handleLogin')
		dispatch({ type: types.SET_STATE, data: { pending: true, errors: [] } });

		try {
			let res = await axios.post(process.env.REACT_APP_API_URL + '/user/login', {
				user: data.email,
				pass: data.password,
			});

			if(res.data.force_reset) {
				dispatch({
					type: types.SET_STATE,
					data: {
						pending: false,
						errors: [],
						loggedIn: false,
						showPasswordChange: true,
						resetToken: res.data.token,
					},
				});
			} else {
				dispatch({ type: types.SET_STATE, data: { ...initialState, loggedIn: true } });
				Cookies.set('authToken', res.data.token);
			}

		} catch(err) {
			dispatch({ type: types.SET_STATE, data: { pending: false, errors: getResponseErrors(err) } });
		}
	},



	handlePasswordChange: (data) => async (dispatch, getState) => {
		dispatch({ type: types.SET_STATE, data: { pending: true, errors: [] } });

		if(data.password_reset1 !== data.password_reset2) {
			return dispatch({
				type: types.SET_STATE,
				data: { pending: false, errors: [ 'Password fields must match.' ] },
			});
		}

		try {
			await axios.post(process.env.REACT_APP_API_URL + '/user/change_password', {
				token: getState().auth.resetToken,
				pass: data.password_reset1,
			});

			dispatch({
				type: types.SET_STATE,
				data: {
					pending: false,
					errors: [],
					loggedIn: false,
					showPasswordChange: false,
					showRelog: true,
					resetToken: null,
				},
			});


		} catch(err) {
			dispatch({ type: types.SET_STATE, data: { pending: false, errors: getResponseErrors(err) } });
		}
	},



	verifyToken: () => async (dispatch) => {
		dispatch({ type: types.SET_STATE, data: { pending: true, loggedIn: false, verifyingToken: true, errors: [] } });

		try {
			await axios.get(process.env.REACT_APP_API_URL + '/user/verify_token', { timeout: 5000 });

			dispatch({
				type: types.SET_STATE,
				data: {
					...initialState,
					loggedIn: true,
					verifyingToken: false,
				},
			});

		} catch(err) {
			dispatch({
				type: types.SET_STATE,
				data: { verifyingToken: false, pending: false, errors: getResponseErrors(err) },
			});
			Cookies.remove('authToken');
		}
	},
};
