import axios from 'axios';
import DelayedPromise from "lib/DelayedPromise";
import { message } from 'antd';



const initialState = {
	loading: false,
	showPDFModal: false,
	PDFmodalDocNum: null,
	PDFmodalDocEntry: null,
	PDFmodalLoading: false,
	showPaymentModal: false,
	paymentPending: false,
	invoices: [],
	invoicePDFs: {},
	selectedRows: [],
	selectedInvoices: [],
	paymentAmounts: {},
	selectedTotal: 0,
	paymentTotal: 0,
	externalError: false,
	showAmountsForm: true,
	showPaymentForm: false,
	showPaymentStatus: false,
	validatingAmounts: false,
	appToken: null,
	paymentRequestSent: false,
	captureError: false,
	capturing: false,
};


export const types = {
	SET_STATE: 'INVOICES/SET_STATE',
	SET_PAYMENT_AMOUNT: 'INVOICES/SET_PAYMENT_AMOUNT',
};




export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		case types.SET_PAYMENT_AMOUNT:
			return {
				...state,
				paymentAmounts: {
					...state.paymentAmounts,
					[ action.key ]: action.value,
				},
			};
		default:
			return state;
	}
}



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),



	resetInvoices: () => ({ type: types.SET_STATE, data: { ...initialState } }),



	resetPDFModal: () => ({
		type: types.SET_STATE,
		data: { showPDFModal: false, PDFmodalDocNum: null, PDFmodalDocEntry: null, PDFmodalLoading: false },
	}),



	openPaymentModal: () => ({
		type: types.SET_STATE,
		data: { showPaymentModal: true },
	}),



	closePaymentModal: () => (dispatch, getState) => dispatch({
		type: types.SET_STATE,
		data: {
			showPaymentModal: false,
			externalError: getState().invoices.externalError,
			showAmountsForm: true,
			showPaymentForm: false,
			showPaymentStatus: false,
			validatingAmounts: false,
			clientToken: null,
			appToken: null,
			paymentRequestSent: false,
			captureError: false,
			capturing: false,
		},
	}),



	refreshInvoices: (mode = 'all', silent = false) => async (dispatch) => {
		dispatch({
			type: types.SET_STATE, data: {
				loading: true,
				invoicePDFs: {},
				selectedRows: [],
				selectedInvoices: [],
				paymentAmounts: {},
				selectedTotal: 0,
				paymentTotal: 0,
			},
		});

		try {
			let invoiceRequest = await DelayedPromise(
				axios.get(`${process.env.REACT_APP_API_URL}/invoices/${mode}?silent=${silent.toString()}`),
				1000,
			);

			dispatch({
				type: types.SET_STATE, data: {
					loading: false,
					invoices: invoiceRequest.data.invoices,

				},
			});
		} catch(err) {
			dispatch({ type: types.SET_STATE, data: { loading: false } });
		}
	},



	toggleSelectAll: () => (dispatch, getState) => {
		let selectedInvoices = getState().invoices.selectedRows.length === 0
							   ? getState().invoices.invoices
								   .filter(row => row.invoice_status === 'Open')
							   : [];

		dispatch({
			type: types.SET_STATE, data: {
				selectedInvoices: selectedInvoices,
				selectedRows: selectedInvoices.map(row => row.key),
				selectedTotal: calculateSelectedTotal(selectedInvoices),
				paymentAmounts: makePaymentAmounts(selectedInvoices),
			},
		});
	},



	toggleSingleSelection: (selected, record) => (dispatch, getState) => {
		if(record.invoice_status === 'Closed') {
			message.info(`Invoice ${record.invoice_number} is closed and cannot be selected for payment`);
			return false;
		}

		let key = record.key;
		let selectedRows = [ ...getState().invoices.selectedRows ];
		let selectedInvoices = [ ...getState().invoices.selectedInvoices ];

		if(selected) {
			selectedRows.push(key);
			selectedInvoices.push(record);
		} else {
			let keyIndex = selectedRows.indexOf(key);
			selectedRows = [
				...selectedRows.slice(0, keyIndex),
				...selectedRows.slice(keyIndex + 1, selectedRows.length),
			];

			selectedInvoices = [
				...selectedInvoices.slice(0, keyIndex),
				...selectedInvoices.slice(keyIndex + 1, selectedInvoices.length),
			];
		}



		dispatch({
			type: types.SET_STATE, data: {
				selectedInvoices: selectedInvoices,
				selectedRows: selectedRows,
				selectedTotal: calculateSelectedTotal(selectedInvoices),
				paymentAmounts: makePaymentAmounts(selectedInvoices),
			},
		});
	},



	viewPDF: (docEntry, docNum, silent = false) => async (dispatch, getState) => {
		let hasPDF = Boolean(getState().invoices.invoicePDFs[ docEntry ]);

		dispatch({
			type: types.SET_STATE, data: {
				PDFmodalLoading: !hasPDF,
				PDFmodalDocEntry: docEntry,
				PDFmodalDocNum: docNum,
				showPDFModal: true,
			},
		});

		if(hasPDF) {
			return true;
		}

		try {
			let pdfRequest = await axios.get(`${process.env.REACT_APP_API_URL}/invoices/view/${docEntry}?silent=${silent.toString()}`, { timeout: 60000 });

			dispatch({
				type: types.SET_STATE, data: {
					PDFmodalLoading: false,
					invoicePDFs: { ...getState().invoices.invoicePDFs, [ docEntry ]: pdfRequest.data.fileData },
				},
			});
		} catch(err) {
			if(err.message && err.message.indexOf('timeout') !== -1) {
				message.error('PDF fetching timed out after 1 minute. Please try again later.');
			}
			dispatch(actions.resetModal());
		}
	},



	updatePaymentAmount: (key, value) => (dispatch, getState) => {
		let maxValue = getState().invoices.paymentAmounts[ key ].maxValue;
		let lastValue = getState().invoices.paymentAmounts[ key ].value;
		value = Number(value);

		if(typeof value !== 'number') {
			value = lastValue;
		}

		if(value === 0) {
			value = maxValue;
		}

		if(value > maxValue) {
			value = maxValue;
		}

		dispatch({
			type: types.SET_PAYMENT_AMOUNT,
			key: key,
			value: {
				maxValue: maxValue,
				value: value,
			},
		});
	},



	validateAmounts: () => async (dispatch, getState) => {
		let payload = getState().invoices.paymentAmounts;
		let url = `${process.env.REACT_APP_API_URL}/payments/validate-amounts`;

		dispatch({
			type: types.SET_STATE,
			data: {
				validatingAmounts: true,
			},
		});

		try {
			let res = await axios.post(url, { amounts: payload });

			dispatch({
				type: types.SET_STATE,
				data: {
					paymentTotal: res.data.total,
					appToken: res.data.app_token,
					validatingAmounts: false,
					showAmountsForm: false,
					showPaymentForm: true,
					showPaymentStatus: false,
				},
			});

		} catch(err) {
			dispatch({
				type: types.SET_STATE,
				data: {
					validatingAmounts: false,
				},
			});
			console.error(err);
		}
	},



	capturePayment: (data) => async (dispatch, getState) => {
		console.log('[Invoices.redux.js] capturePayment')
		let state = getState().invoices;
		let url = `${process.env.REACT_APP_API_URL}/payments/capture-payment`;
		const { appToken } = state;

		dispatch(actions.setState({
			capturing: true,
			paymentRequestSent: true,
			showPaymentStatus: true,
			showPaymentForm: false,
		}));

		try {
			let res = await axios.post(url, {
				app_token: appToken,
				...data
			});

			console.log('[Invoices.redux.js] capturePayment res:');
			console.log(res);

			if (res.data && res.data.errorTime) {
				console.error('[Invoices.redux.js] capturePayment errorTime')
				console.error(res.data.errorTime);
			}

			if (res.data && res.data.errors) {
				console.error(res.data.errors);

				if (res.data.errors.includes('transaction_id was null')) {
					throw new Error('transaction_id was null');
				}
			}

			dispatch(actions.setState({
				captureError: false,
				capturing: false,
			}));

		} catch(err) {
			console.error('[Invoices.redux.js] capturePayment catch err');
			console.error(err);
			dispatch(actions.setState({
				captureError: true,
				capturing: false,
			}));
		}
	},
};


function calculateSelectedTotal(rows) {
	if(!rows.length) {
		return 0;
	}

	return Number(rows.reduce((accumulator, currentValue) => {
		return accumulator + currentValue.invoice_balance;
	}, 0).toFixed(2));
}


function makePaymentAmounts(rows) {
	let res = {};

	for(let i = 0; i < rows.length; i++) {
		let row = rows[ i ];
		res[ row.invoice_key ] = { maxValue: row.invoice_balance, value: row.invoice_balance };
	}

	return res;
}